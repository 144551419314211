<template>
  <div style="font-size: 0.14rem;color: #fff;">
    <div style="padding:0 0.36rem;margin-top: 0.2rem">
      <template :span="8" :sm="12" v-for="(item, index) in statistics">
        <span>{{ item.label }}</span>
        <span style="color: #00F9FF;font-weight: bold;margin-right: 0.5rem">{{ item.count }}</span>
      </template>
    </div>
    <div style="padding:0 0.36rem;margin-top: 0.4rem">
      <el-row :gutter="40">
        <el-col :span="12">
          <div class="clearfix">
            <img src="/static/images/man.png" style="width: 0.13rem;height: 0.35rem;float: left;padding: 0 0.1rem"/>
            <img src="/static/images/woman.png" style="width: 0.13rem;height: 0.35rem;float: right;padding: 0 0.1rem"/>
          </div>
          <div style="width: 100%;margin-top: 4px">
            <div style="display: flex;">
              <div
                style="height: 0.34rem;background: linear-gradient(-90deg, #3E98FC, #6EB2FF);border-bottom-left-radius: 0.34rem;border-top-left-radius: 0.34rem;line-height: 0.34rem"
                :style="'width:' + sexRatio.boarderMaleScale + '%'">
                <span style="font-size: 0.14rem;padding:0 0.1rem" v-if="totalRatio">{{ sexRatio.boarderMaleScale }}%</span>
                <span style="font-size: 0.14rem;padding:0 0.1rem" v-if="!totalRatio">0%</span>
              </div>
              <div
                style="height: 0.34rem;background: linear-gradient(-90deg, #555FE9, #7079F1);width: 100%;border-bottom-right-radius: 0.34rem;border-top-right-radius: 0.34rem;line-height: 0.34rem;text-align: right"
                :style="'width:' + sexRatio.boarderFemaleScale + '%'">
                <span style="font-size: 0.14rem;padding:0 0.1rem" v-if="totalRatio">{{ sexRatio.boarderFemaleScale }}%</span>
                <span style="font-size: 0.14rem;padding:0 0.1rem" v-if="!totalRatio">0%</span>
              </div>
            </div>
          </div>
          <div style="margin-top: 0.16rem">
            <p style="font-size: 0.12rem;color: #B1CEF4;text-align: center">寄宿生男女比例</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="width: 100%;height: 1rem">
            <pie :data="dorm"></pie>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    pie: () => import('./dormPie')
  },

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      statistics: [
        { label: '寄宿类学校数：', count: 0, code: 'boarderSchoolNum' },
        { label: '宿舍楼数：', count: 0, code: 'dormBuildNum' },
        { label: '寄宿生数：', count: 0, code: 'boarderNum' }
      ],
      sexRatio: {
        boarderFemaleScale: 50,
        boarderMaleScale: 50
      },
      totalRatio: 0,
      dorm: [

      ],
      dormData: [
        { value: 0, name: '在寝', code: 'inDormNum' },
        { value: 0, name: '未在寝', code: 'notInDormNum' }
      ]

    }
  },

  watch: {
    data (newData) {
      this.init(newData)
    }
  },

  mounted () {
    this.init(this.data)
  },

  methods: {
    init (data) {
      this.statistics.forEach(item => {
        if (typeof data[item.code] !== 'undefined') {
          item.count = data[item.code]
        }
      })
      let dormTotal = 0
      this.dormData.forEach(item => {
        if (typeof data[item.code] !== 'undefined') {
          item.value = data[item.code]
        }
        dormTotal += item.value
      })
      if (dormTotal) this.dorm = this.dormData

      const total = data.boarderFemaleNum + data.boarderMaleNum
      if (total) {
        this.sexRatio.boarderFemaleScale = ((data.boarderFemaleNum / total).toFixed(4) * 100).toFixed(2)
        console.log(this.sexRatio.boarderFemaleScale)
        this.sexRatio.boarderMaleScale = 100 - this.sexRatio.boarderFemaleScale
      }
      this.totalRatio = total
      // for (let i in this.sexRatio) {
      //   if (typeof data[i] !== 'undefined') {
      //     this.sexRatio[i] = (Number(data[i]) * 100).toFixed(2)
      //   }
      // }
    }
  }
}
</script>
